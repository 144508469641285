.cite{
  margin: 72px auto;
  max-width: 1024px;
  padding-bottom: 15px;
  padding-top: 15px;
  position: relative;
  text-align: center;

  &:before,
  &:after{
    background-color: var(--color-black);
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 60px;
  }

  &:before{
    top: 0;
  }

  &:after{
    bottom: 0;
  }
}

.cite__quotation{
  font-style: italic;
  margin-bottom: 15px;

  p{
    line-height: 1.7;
  }
}

.cite__author{
  font-size: 0.8125rem;
  font-weight: 500;
  text-transform: uppercase;
}