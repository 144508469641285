.front-focus{
  margin-bottom: 90px;
}

.front-focus__media{
  height: 450px;

  > *{
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.front-focus__title{
  border-bottom: 3px solid var(--color-black);
  border-top: 3px solid var(--color-black);
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.front-focus__link{
  align-items: center;
  color: var(--color-black);
  display: flex;
  justify-content: space-between;
  padding: 15px 2%;
  text-decoration: none;
  transition: background-color .2s, color .2s;

  &:hover,
  &:focus{
    background-color: var(--color-black);
    color: var(--color-white);
  }
}

.front-focus__label{
  width: calc(100% - 30px);
}