.front-focus {
  margin-bottom: 90px;
}

.front-focus__media {
  height: 450px;
}
.front-focus__media > * {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.front-focus__title {
  border-bottom: 3px solid var(--color-black);
  border-top: 3px solid var(--color-black);
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .front-focus__title {
    font-size: 1.5rem;
  }
}

.front-focus__link {
  align-items: center;
  color: var(--color-black);
  display: flex;
  justify-content: space-between;
  padding: 15px 2%;
  text-decoration: none;
  transition: background-color 0.2s, color 0.2s;
}
.front-focus__link:hover, .front-focus__link:focus {
  background-color: var(--color-black);
  color: var(--color-white);
}

.front-focus__label {
  width: calc(100% - 30px);
}

.front-news {
  margin-bottom: 90px;
}

.front-news__items {
  border-top: 3px solid var(--color-black);
  overflow: hidden;
}

.front-news__item {
  border-bottom: 3px solid var(--color-black);
  list-style: none;
  transition: background-color 0.2s, color 0.2s;
}
.front-news__item:hover, .front-news__itemfocus {
  background-color: var(--color-black);
  color: var(--color-white);
}

.front-news__link {
  align-items: center;
  color: inherit;
  display: inline-flex !important;
  font-size: 1.3rem;
  font-weight: 500;
  gap: 9px;
  padding-bottom: 15px;
  padding-top: 15px;
  text-decoration: none;
  text-transform: uppercase;
}
.front-news__link.marquee3k__copy {
  margin-right: 15px;
}
@media (min-width: 768px) {
  .front-news__link {
    font-size: 1.5rem;
  }
}

.cite {
  margin: 72px auto;
  max-width: 1024px;
  padding-bottom: 15px;
  padding-top: 15px;
  position: relative;
  text-align: center;
}
.cite:before, .cite:after {
  background-color: var(--color-black);
  content: "";
  display: block;
  height: 3px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 60px;
}
.cite:before {
  top: 0;
}
.cite:after {
  bottom: 0;
}

.cite__quotation {
  font-style: italic;
  margin-bottom: 15px;
}
.cite__quotation p {
  line-height: 1.7;
}

.cite__author {
  font-size: 0.8125rem;
  font-weight: 500;
  text-transform: uppercase;
}