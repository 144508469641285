.front-news{
  margin-bottom: 90px;
}

.front-news__items{
  border-top: 3px solid var(--color-black);
  overflow: hidden;
}

.front-news__item{
  border-bottom: 3px solid var(--color-black);
  list-style: none;
  transition: background-color .2s, color .2s;

  &:hover,
  &focus{
    background-color: var(--color-black);
    color: var(--color-white);
  }
}

.front-news__link{
  align-items: center;
  color: inherit;
  display: inline-flex !important;
  font-size: 1.3rem;
  font-weight: 500;
  gap: 9px;
  padding-bottom: 15px;
  padding-top: 15px;
  text-decoration: none;
  text-transform: uppercase;

  &.marquee3k__copy{
    margin-right: 15px;
  }

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}